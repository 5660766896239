/**
 * Sync data with local storage
 *
 * @param key localStorage key
 * @param defaultValue default value for the state (has to be json serializable).
 * @returns
 */
export function useLocalStorage<T extends Record<string, any>>(
  key: string,
  defaultValue: T
) {
  const state = ref<T>(parseState())

  function parseState(): T {
    const state = JSON.parse(localStorage.getItem(key) || '{}')
    return {
      ...defaultValue,
      ...state,
    }
  }

  function updateState() {
    localStorage.setItem(key, JSON.stringify(state.value))
  }

  watchEffect(() => {
    updateState()
  })

  return state
}
