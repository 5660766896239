import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/planung',
    name: 'PlannerOverview',
    component: () => import('../Views/ResourcePlanner/PlannerOverview.vue'),
    meta: { auth: true },
  },
  {
    path: '/einsatzplaner/planung',
    name: 'ResourceplannerPublicView',
    component: () => import('../Views/ResourcePlanner/PublicView.vue'),
    meta: { auth: 'optional' },
  },
  {
    path: '/resources/:resourceId/fields',
    name: 'ResourceMap',
    props: true,
    meta: { auth: 'optional' },
    component: () => import('../Views/ResourcePlanner/ResourceMap.vue'),
  },
  {
    path: '/rapport',
    name: 'RapportOverview',
    component: () => import('../Views/Rapport/RapportOverview.vue'),
    meta: { auth: true },
  },
  {
    path: '/rapport/week/:week',
    name: 'RapportWeek',
    component: () => import('../Views/Rapport/RapportWeek.vue'),
    meta: { auth: true },
  },
  {
    path: '/rapport/:id',
    name: 'Rapport',
    component: () => import('../Views/CustomerPortal/Rapports/Rapport.vue'),
    meta: { auth: true },
    props: true,
  },
  {
    path: '/resource-planner/plan',
    name: 'ResourcePlanner',
    component: () => import('../Views/ResourcePlanner/ResourcePlanner.vue'),
    meta: { auth: true },
  },
  {
    path: '/resource-planner/distribute-hours',
    name: 'DistributeHours',
    component: () => import('../Views/ResourcePlanner/DistributeHours.vue'),
    meta: { auth: true },
  },
  {
    path: '/resource-planner/tools',
    name: 'Tools',
    component: () => import('../Views/ResourcePlanner/Tools.vue'),
    meta: { auth: true },
  },
  {
    path: '/resource-planner/cars',
    name: 'Cars',
    component: () => import('../Views/ResourcePlanner/Cars.vue'),
    meta: { auth: true },
  },
]
