import * as Sentry from '@sentry/vue'
import { httpClientIntegration } from '@sentry/integrations'

export default {
  install(app) {
    if (
      process.env.NODE_ENV === 'development' ||
      import.meta.env.VITE_DISABLE_POSTHOG === 'true'
    ) {
      return
    }

    Sentry.init({
      app,
      dsn: 'https://0183085136b175bb9a7dd8837b9fc7b4@o4508048577200128.ingest.de.sentry.io/4508048579887184',
      // tunnel: '/api/sentry/tunnel',
      integrations: [
        // Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
        }),
        httpClientIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        '.test',
        /^https:\/\/rapport-brunnereichhof\.ch\/api/,
        /^https:\/\/staging\.rapport-brunnereichhof\.ch\/api/,
      ],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,

      ignoreErrors: ['canceled'],

      beforeSend(event) {
        return event
      },
    })
  },
}
