<template>
  <span ref="elementRef" :class="classNames">
    <slot></slot>
  </span>
</template>

<script setup lang="ts">
import {
  removeTooltip,
  setupTooltip,
  type TooltipElement,
} from '@/directives/tooltip'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  /** The size of the icon */
  size: {
    type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'>,
    default: 'md',
    description: 'The size of the icon123',
  },
})

const { t } = useI18n()

const elementRef = ref<HTMLElement | null>(null)

const defaultTooltip = computed(() => {
  if (!elementRef.value) return null
  const iconName = elementRef.value.textContent?.trim()

  switch (iconName) {
    case 'edit':
      return t('AppIcon.edit')
    case 'delete':
      return t('appIcon.delete')
    case 'picture_as_pdf':
      return t('appIcon.picture_as_pdf')
    default:
      return null
  }
})

const classNames = computed(() => {
  return {
    'material-symbols-rounded material-icon icon': true,
    'text-xs leading-3': props.size === 'xs',
    'text-base leading-4': props.size === 'sm',
    'text-xl leading-5': props.size === 'md',
    'text-2xl leading-6': props.size === 'lg',
    'text-3xl leading-7': props.size === 'xl',
    'text-4xl leading-8': props.size === '2xl',
    'text-5xl leading-9': props.size === '3xl',
  }
})

onMounted(() => {
  if (defaultTooltip.value && elementRef.value) {
    const parentElement = elementRef.value.parentElement as TooltipElement
    // If there is already registered a tooltip on the parent element, don't register another one.
    if (parentElement?._showTooltip) {
      return
    }

    // It should only show a tooltip if the button has no text and therefore is only an icon.
    // Eg <AppButton><AppIcon/>edit</AppIcon></AppButton> should show a tooltip.
    // Eg <AppButton><AppIcon>edit</AppIcon> bearbeiten </AppButton> should not show a tooltip.
    const children = Array.from(parentElement?.childNodes)

    const hasButtonText = children.some((child) => {
      const textContent = (child.textContent ?? '').trim()
      return (
        textContent !== '' &&
        elementRef.value?.textContent?.trim() !== textContent &&
        child.nodeType !== Node.COMMENT_NODE
      )
    })
    if (hasButtonText) return

    // If the icon is wrapped in a button, use the button as the tooltip target.
    const element =
      parentElement && parentElement.tagName === 'BUTTON'
        ? parentElement
        : elementRef.value
    setupTooltip(element, defaultTooltip.value, {
      orientation: 'top',
    })
  }
})

onBeforeUnmount(() => {
  if (elementRef.value) {
    removeTooltip(elementRef.value)
  }
})
</script>
