import axios from '@/plugins/axios'
import { ApiService } from '../ApiService'

class PlannerEntryService extends ApiService {
  public async createPlannerEntry(
    resourceId: number,
    payload: Record<string, any>
  ) {
    return this.makeRequest<{
      plannerEntry: Domain.ResourcePlanner.DTO.PlannerEntryData
      rapportdetails: Domain.ResourcePlanner.DTO.RapportdetailData[]
    }>(
      'createPlannerEntry',
      () => axios.$post(`resources/${resourceId}/planner-entries`, payload),
      { collection: false }
    )
  }

  public deletePlannerEntry(plannerEntryId: number) {
    return this.makeRequest<void>(
      'deletePlannerEntry',
      () => axios.$delete<void>(`planner-entries/${plannerEntryId}`),
      { saving: true }
    )
  }

  public updatePlannerEntry(
    original: Domain.ResourcePlanner.DTO.PlannerEntryData,
    data: Partial<Domain.ResourcePlanner.DTO.PlannerEntryData>
  ) {
    return this.optimisticUpdate(original, data, (id, data) =>
      this.makeRequest<void>(
        'updatePlannerEntry',
        () => axios.$patch(`planner-entries/${id}`, data),
        { saving: true }
      )
    )
  }
}

export const plannerEntryService = new PlannerEntryService()
