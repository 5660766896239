<template>
  <div class="home"></div>
</template>

<script>
import { UserType } from '@/utils'
import { useRunSheetStore } from '@/store/runSheets'

export default {
  mounted() {
    if (!this.$auth.user().is_password_changed)
      this.$router.replace('/profile/change-password')
    else if (this.$auth.user().type === UserType.Customer) {
      this.$router.replace('/kundenportal')
    } else if (
      this.$auth.user().hasPermission(['superadmin'], ['dashboard_read'])
    )
      this.$router.replace('/dashboard')
    else if (this.$auth.user().hasPermission([], ['timerecord_read_write']))
      this.$router.replace('/time')
    else if (this.$auth.user().hasPermission([], ['rapport_read']))
      this.$router.replace('/rapport')
    else if (
      this.$auth
        .user()
        .hasPermission([], ['employee_preview_read', 'employee_read'])
    )
      this.$router.replace('/employee')
    else if (this.$auth.user().hasPermission([], ['roomdispositioner_read']))
      this.$router.replace('/roomdispositioner')
    else if (this.$auth.user().hasPermission([], ['hourrecord_read']))
      this.$router.replace('/hourrecords')
    else if (this.$auth.user().hasPermission([], ['worker_read']))
      this.$router.replace('/worker')
    else if (this.$auth.user().hasPermission([], ['customer_read']))
      this.$router.replace('/customer')
    else if (
      this.$auth
        .user()
        .hasPermission(
          [],
          ['timerecord_stats', 'evaluation_customer', 'evaluation_employee']
        )
    ) {
      this.$router.replace('/evaluation')
    } else if (this.$auth.user().type === UserType.Employee)
      this.$router.replace('/planung')
    else {
      this.loadRunSheets()
    }
  },

  methods: {
    loadRunSheets() {
      const runSheetStore = useRunSheetStore()

      runSheetStore.fetchRunSheetTemplates().then((runSheets) => {
        if (runSheets.length > 0) {
          this.$router.replace(`/forms/runsheet/${runSheets[0].slug}`)
        }
      })
    },
  },
}
</script>
