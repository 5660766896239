<template>
  <component
    :is="form ? ValidationForm : 'form'"
    v-bind="form ? { form, validationSchema } : {}"
    :class="
      cn(
        'flex h-real-screen max-h-screen w-full max-w-full flex-col justify-between bg-white shadow-lg sm:size-auto sm:h-auto sm:max-h-[calc(100vh-60px)] sm:w-auto sm:rounded-lg',
        props.class
      )
    "
    @submit.prevent="$emit('submit')"
  >
    <div
      class="flex min-h-0 shrink flex-col"
      :class="{
        'pb-4': disableActions,
      }"
    >
      <div class="flex shrink items-start justify-between p-4">
        <div>
          <slot name="title">
            <h2 class="text-xl font-medium">{{ title }}</h2>
          </slot>
          <slot name="sub-title">
            <p class="text-sm text-gray-500">{{ subTitle }}</p>
          </slot>
        </div>

        <div class="flex items-center gap-2">
          <slot name="header-actions"></slot>
          <AppButton
            v-tooltip="$t('general.cancel')"
            icon="close"
            text
            class="-m-1"
            color="black"
            @click="$emit('cancel')"
          />
        </div>
      </div>

      <div class="overflow-y-auto px-4 pb-px [&>*]:max-w-full">
        <slot></slot>
      </div>
    </div>

    <div v-if="!disableActions" class="p-4">
      <p
        v-for="(w, index) in computedWarnings"
        :key="`${w.title}-${index}`"
        class="mb-1 w-full max-w-full text-sm sm:text-base"
        :class="{
          'text-orange-500': w.type === 'warning' || !w.type,
          'font-semibold text-red-500': w.type === 'error',
        }"
        @click="w.action"
      >
        <span v-tooltip="w.description">
          {{ w.title }}
        </span>
      </p>
      <div class="flex">
        <slot name="actions">
          <AppButton text color="black" @click="$emit('cancel')">
            {{ $t('general.cancel') }}
          </AppButton>
          <AppButton
            type="submit"
            color="primary"
            class="ml-auto"
            dusk="save-button"
            :loading="saving || loading"
          >
            {{ $t('general.save') }}
          </AppButton>
        </slot>
      </div>
    </div>
  </component>
</template>

<script lang="ts"></script>

<script setup lang="ts">
import { cn } from '@/utils/css-helpers'
import ValidationForm from '../Fields/ValidationForm.vue'

export type Warning = {
  title: string
  description?: string
  action?: () => void
  type?: 'error' | 'warning'
}

const props = withDefaults(
  defineProps<{
    card?: boolean
    color?: string
    title?: string | null
    subTitle?: string | null
    valid?: boolean
    form?: Record<string, any> | null
    validationSchema?: Record<string, any> | null
    class?: string
    saving?: boolean
    loading?: boolean
    disableActions?: boolean
    warning?: Warning | null | string | Warning[]
  }>(),
  {
    card: true,
    color: 'primary',
    title: null,
    subTitle: null,
    valid: true,
    form: null,
    validationSchema: null,
    class: '',
    saving: false,
    loading: false,
    disableActions: false,
    warning: null,
  }
)

defineEmits(['submit', 'cancel'])

const computedWarnings = computed(() => {
  if (!props.warning) {
    return []
  }

  if (!Array.isArray(props.warning)) {
    return [convertWarningToObject(props.warning)]
  }

  return props.warning.map(convertWarningToObject)
})

function convertWarningToObject(warning: Warning | string) {
  if (typeof warning === 'string') {
    return {
      title: warning,
    }
  }

  return warning
}
</script>

<style lang="scss" scoped>
.card-content {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
}
</style>
